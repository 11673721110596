@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
  

/* Navbar container */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #f5f5f5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Navbar logo */
.navbar-logo {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
}

/* Navbar links */
.navbar-links {
    list-style-type: none;
    display: flex;
    gap: 1.5rem;
    margin: 0;
    padding: 0;
}

/* Navbar link items */
.navbar-links a {
    text-decoration: none;
    color: #333;
    font-weight: 600;
    transition: color 0.2s;
}

.navbar-links a:hover {
    color: #555;
}


h1 {
  color: #444;
}

p {
  line-height: 1.6;
}

input[type="text"], button {
  padding: 10px;
  margin: 5px;
  font-size: 16px;
}

button {
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
}

button:hover {
  background-color: #0056b3;
}

.chat-box {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
}

.user {
  text-align: right;
  margin: 10px;
  padding: 8px;
  background-color: #e0f7fa;
  border-radius: 5px;
  display: inline-block;
}

.bot {
  text-align: left;
  margin: 10px;
  padding: 8px;
  background-color: #e3f2fd;
  border-radius: 5px;
  display: inline-block;
}

/* Ensure tables fit within mobile screens */
table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 0.5rem;
    text-align: left;
    white-space: nowrap;
  }
  
  thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  
  tbody {
    overflow-y: auto;
  }
  
  /* Add horizontal scrolling on small screens */
  .table-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  /* Set a maximum width for table cells to prevent overflow */
  td {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  